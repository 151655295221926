@import '~@bottomless/common/style/common';

.create-comment-wrapper {
  padding: 1rem;
  background: white;
  border-radius: 15px;

  .content-container .form-group {
    margin-bottom: 0 !important;
  }
  .invalid-feedback {
    display: none !important;
  }
}

.activity-container,
.date-container {
  position: relative;
  padding-left: 40px;
  padding-right: 20px;
  margin-left: 1rem;

  .timeline-node {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    .circle {
      position: absolute;
      top: 22px;
      left: 9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #f1f1f1;
      border: 1px solid $gray-500;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $gray-500;
      }
    }

    .line {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 14px;
      width: 1.5px;
      background-color: $gray-500;
    }
  }
}

.comment-container {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  padding-right: 20px;
}

.date-container {
  padding: 15px;
  color: $gray-600;
}
