.vendor-portal-analytics {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 2rem;

  .rotate {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
