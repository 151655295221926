@import '~@bottomless/common/style/common';

.panel {
  &-layout {
    display: flex;
    height: 100vh;
  }

  &-nav {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(100, 100, 100, 0.125);

    .nav-section:not(:first-child):before {
      content: '';
      display: block;
      height: 1px;
      background: $card-border-color;
      margin: 1rem 1.2rem;
    }

    .badge {
      @include font-size-rem(0.65);
      line-height: 0.5rem;
      right: 1rem;
      top: 0.95rem;
      width: 1rem;
      height: 1rem;
      display: block;
      padding-left: 1px;
      padding-right: 1px;
      border-radius: 50%;
    }

    .grouped-nav {
      .grouped-sub-option {
        margin-left: 0.5rem;
        font-weight: 500;
        color: $secondary;
      }
    }

    a,
    .grouped-option {
      @include font-size-rem(0.8);
      @include transition($btn-transition);
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding: 0.7rem 1.2rem;
      margin: 0.3rem 0;
      color: black;
      position: relative;

      .vendor-logo {
        max-height: 80px;
        max-width: 140px;
      }

      &,
      &:hover {
        text-decoration: none;
      }
    }

    a {
      &:not(.panel-nav-logo).active,
      &:not(.panel-nav-logo):hover {
        @include transition($btn-transition);
        color: $primary;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background: $primary;
          border-radius: 0px 4px 4px 0px;
        }
      }
    }
  }

  &-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    position: relative;
    background: #f1f1f1;

    &.with-banner {
      padding: 0 !important;
    }

    .panel-title {
      min-width: 16.66667%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
  }

  &-right-side-nav {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    max-width: 11.25rem;

    .nav-section {
      height: 97vh;
    }
  }
}

.panel-admin {
  .panel-title {
    margin-right: 2rem;
  }
}

.toasts {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 80px;

  .toast {
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    margin-bottom: 0.5rem;

    &-body {
      padding: 0.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .toasts {
    top: 0;
    bottom: initial;
    padding-right: 1rem;
    padding-top: 1rem;

    .toast {
      width: 350px;
      margin-bottom: initial;

      &-body {
        padding: 0.5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .panel {
    &-content {
      overflow-y: unset !important;
    }

    &-layout {
      flex-direction: column-reverse;
      height: auto;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;

      &-with-banner {
        .panel-wrapper {
          height: auto;
        }
      }
    }

    &-wrapper {
      height: auto;
    }

    &-wrapper.no-data {
      height: 100vh;
    }

    &-nav {
      justify-content: center;
      flex-direction: row;
      border-right: none;
      border-top: 1px solid $gray-300;
      bottom: 0;
      position: fixed;
      width: 100%;
      background-color: #ffffff;
      z-index: 100;

      .nav-section:not(:first-child):before {
        width: 1px;
        height: auto;
        margin: 0.5rem 0 0.5rem -10px;
      }

      .badge {
        @include font-size-rem(0.5);
        line-height: 0.4rem;
        right: -0.4rem;
        top: 0.25rem;
        width: 0.8rem;
        height: 0.8rem;
      }

      a {
        flex-direction: column;
        @include font-size-rem(0.7);

        &:not(.panel-nav-logo).active,
        &:not(.panel-nav-logo):hover {
          &:before {
            display: none;
          }
        }

        > span {
          margin-left: 0 !important;
        }
      }

      &-logo {
        display: none !important;
      }

      > div {
        &:first-child {
          flex: 4;
        }

        &:last-child {
          flex: 1;
        }

        flex-direction: row;
        justify-content: space-around;
      }

      &-3 {
        > div {
          &:first-child {
            flex: 3;
          }

          &:last-child {
            flex: 1;
          }
        }
      }

      &-2 {
        > div {
          &:first-child {
            flex: 2;
          }

          &:last-child {
            flex: 1;
          }
        }
      }

      a {
        margin: 0;
        padding: 0.4rem 0 0.25rem 0;
      }
    }
  }
}
