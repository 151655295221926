.page-vendor-products {
  .vendor-product {
    img {
      object-fit: cover;
      height: 50px;
      width: 50px;
    }
  }

  .filter-search {
    flex: 1;

    .form-group,
    label,
    input {
      flex: 1;
    }
  }
}
