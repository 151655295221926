@import '~@bottomless/common/style/common';

.page-footer {
  .fa {
    font-size: 2rem;
  }

  .copyright {
    margin-top: 2rem;
  }
}

.footer-logo-wrapper {
  padding: 3rem 0;
  background: #eae9e9;

  .tc-logo {
    max-width: 140px;
  }

  .geekwire-logo {
    max-width: 250px;
  }

  .yc-logo {
    max-width: 400px;
  }

  .backed-by {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-down(sm) {
  .footer-logo-wrapper {
    .tc-logo {
      max-width: 130px;
    }

    .geekwire-logo {
      max-width: 200px;
    }

    .yc-logo {
      max-width: 300px;
    }
  }
}
