.page-vendor-unprocessed-orders {
  .large-order-alert {
    width: fit-content;
    border: 3px solid #17a2b8;
    text-align: center;
    font-weight: bold;
    padding-top: 15px;
    margin: 0 auto 1rem auto;
  }

  .non-12oz-variant {
    text-decoration: underline;
    font-weight: 700;
  }

  .non-whole-beans-grind {
    text-decoration: underline;
    font-weight: 700;
  }
}
