.total-amount {
  flex-direction: row-reverse;
  padding-right: 2.25rem;
}

.payout-size {
  font-size: large;
  color: black;
}

.regenerate-button {
  width: 14rem;
}
