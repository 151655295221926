@import '~@bottomless/common/style/common';

$color-hint-of-green: #eeffee;
$color-cerulean: #0095d4;

section.sign-up {
  background: $color-hint-of-green;
}

.page-landing {
  &-content {
    padding-top: 100px;
  }

  .heading {
    text-align: center;
  }

  section.how-it-works {
    margin: 5rem 0;

    .col-12 {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    img {
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  section.a-better-way {
    margin: 5rem 0;

    p {
      font-size: 1.4rem;
    }
  }

  section.faq {
    margin: 5rem auto;
    max-width: 600px;
    h2 {
      text-align: center;
    }
    .accordion {
      border-bottom: 1px solid $gray-200;

      h3 {
        padding: 1rem 0;
        margin-bottom: 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3em;

        &:after {
          font-size: 1rem;
          content: '\f067';
          font-family: 'ForkAwesome';
        }
      }

      p {
        font-size: 1.1rem;
      }

      &.open {
        h3:after {
          content: '\f00d';
        }
      }
    }
  }

  section.testimonials {
    margin: 5rem auto;

    .fa {
      font-size: 3.4rem;
      color: $color-cerulean;
      margin-bottom: 2rem;
    }

    .testimonial {
      font-size: 1.1rem;

      &-person {
        font-size: 0.9rem;
      }
    }
  }

  section.sign-up {
    form {
      max-width: 500px;
      margin: 0 auto;
    }

    p {
      margin-bottom: 1.5rem;
      font-size: 1.1rem;
    }
  }

  @include media-breakpoint-down(md) {
    section.how-it-works {
      .col-12 {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .page-landing-content {
      padding-top: 70px;
    }

    section.how-it-works p,
    section.a-better-way p {
      font-size: 1rem;
    }

    section.how-it-works {
      .img-fluid {
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }
}
