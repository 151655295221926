.absolute-image {
  position: absolute;
  top: 3rem;
  left: 3rem;
}

.header-section {
  justify-content: center;
}

.form-inline .form-control {
  width: 100%;
}
