@import '~@bottomless/common/style/common';

.page-ordergen-details {
  .ordergen-user {
    h6 {
      text-transform: none;
    }

    .product-details .font-weight-bold {
      font-weight: 400 !important;
    }

    .grind {
      font-size: 0.8rem;
      letter-spacing: -0.384px;
    }

    .lazy-load-image-loaded {
      height: 120px;
      width: 120px;
      object-fit: contain;
      padding: 0.5rem;
      border: 1px solid $card-border-color;
      border-radius: 1rem;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    .action-buttons {
      gap: 15px;
    }

    .box.mb-3.mb-0 {
      margin-bottom: 0 !important;
    }

    .connected-date {
      display: none;
    }

    .last-weight {
      font-size: 1rem !important;
    }

    .card-user .card-body {
      display: flex;
      flex-direction: column;

      > div {
        flex-grow: 1;
        align-items: stretch !important;

        > .flex-grow-1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  .box.box-long .menu-actions > button {
    margin-top: -8px;
  }

  .variant-price {
    .discount-tooltip {
      display: none !important;
    }

    .text-strikethrough + span + .discount-tooltip {
      display: inline-block !important;
    }
  }
}

.view-in-shopify {
  line-height: 12px;
  text-transform: none;
}

.modal-line-items {
  .lazy-load-image-loaded {
    height: 70px;
    width: 70px;
    object-fit: contain;
    padding: 0.25rem;
    border: 1px solid $card-border-color;
    border-radius: 1rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.form-admin-edit-product {
  .first-product-edit-row {
    padding-right: 60px;
  }
}

.shopify-note-container {
  background-color: $gray-300;
  border-radius: 0;
  box-shadow: none !important;
  opacity: 1 !important;
  .card-body {
    padding: 0.5rem 1.5rem;
  }
}
