.box {
  border-radius: 1rem;
  border: none;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 5px;

  .card-footer:last-child {
    border-radius: 0 0 1rem 1rem;
  }

  &-actions-up {
    .menu-actions {
      display: flex;
      align-items: flex-start;
    }

    .chevron {
      padding-top: 0.5rem;
    }
  }
}

.box .box.mb-3 {
  margin-bottom: 0 !important;
  padding-bottom: 0.5rem !important;
  margin-top: 0 !important;
}

.box .box {
  border: none;
  box-shadow: none;
  padding: 0 0 1rem 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  .card-footer {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &.mb-3:last-child {
    margin-bottom: 0 !important;
  }
}
