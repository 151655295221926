@import '~@bottomless/common/style/common';

.scale-circle {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.scale-circle.connected {
  background: $success;
}
.scale-circle.disconnected {
  background: $danger;
}

.text-color-black {
  color: black !important;
}
