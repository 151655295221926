@import '~@bottomless/common/style/common';

.page-simple {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: $font-family-sans-serif;
    font-weight: 300;
  }

  .page-content {
    flex-grow: 2;
  }

  &--stretch {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    min-height: 100vh;
  }
}
