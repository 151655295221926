.batch-shipment-check {
  margin-top: -20px;
}

.batch-shipment-orders {
  overflow: auto;
}

.no-border {
  border: none;
}
